import React from "react";
import { Container, Breadcrumb} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Post } from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';

function startabusiness() {
    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Tarifs des banques – Les principaux frais bancaires</title>
                <meta name="description" content="
              Tenue de compte, cotisation de la carte ou encore assurance moyens de paiement, découvrez tous les tarifs cachés des banques." />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/academie/`} style={{color: 'black'}}>Banque Académie</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Tarifs Des Banques: Les Principaux Frais Bancaires</Breadcrumb.Item>
      	 </Breadcrumb>
        	<Title id='mobileTitle'>Tarifs des banques – Les principaux frais bancaires</Title>
			<StaticImage
		  	src='../../images/tarifs-des-banques.png'
			width={1200}
			alt='Tarifs des banques'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<Post>
		<p>	Soumises à des obligations réglementaires, les banques sont désormais contraintes d’être transparentes envers les consommateurs concernant leurs tarifs en publiant leur grille tarifaire annuelle. Les consommateurs ont connaissance des frais auxquels ils sont exposés et peuvent plus facilement choisir une banque moins chère.
		</p>
<h2>Les frais bancaires courants</h2>
<h3>Les frais de tenue de compte</h3>
<p>Ils sont destinés à rémunérer les moyens humains et informatiques liés à la gestion du compte. Leur montant s’élève annuellement de 0 € à 36 € avec une moyenne de 24 €.
</p>
<h3>Le service d’alerte SMS sur la situation du compte</h3>
<p>Proposée dans les services de banque à distance, la souscription à un abonnement de service d’alerte permet de recevoir des SMS afin d’informer le client sur différents mouvements de son compte bancaire.
</p>
<h3>La cotisation carte bancaire</h3>
<p>La cotisation pour bénéficier d’une carte bancaire représente un des coûts les plus importants dans les frais annuels des banques traditionnelles. Le coût est variable selon le type et la gamme de cartes. C’est aussi sur ce point que les banques en ligne ont décidé de réduire les frais en mettant à disposition du client une carte de paiement gratuite.
</p>
<h3>Les retraits et paiements</h3>
<p>Les retraits peuvent être facturés par les banques classiques lorsqu’ils sont réalisés dans un autre établissement bancaire. Quant aux paiements, ils sont toujours gratuits. Quoi qu’il en soit, lorsque ces opérations sont effectuées hors zone euro, des coûts pourront y être associés.
</p>
<h3>L’assurance des moyens de paiement</h3>
<p>Certains consommateurs choisissent de bénéficier d’une couverture élargie concernant les moyens de paiement. Cette assurance permet notamment de prendre en charge les vols d’espèces commis à un distributeur ou encore d’être couvert en cas de vol ou perte des papiers d’identité, clés ou appareil mobile.
</p>
<h2>Les frais liés aux incidents</h2>
<h3>Les découverts</h3>
<p>Un compte à découvert peut entraîner un certain nombre de frais tels que des intérêts débiteurs et des commissions d’intervention et qui sont communément appelés des agios. Si le titulaire souhaite bénéficier d’une autorisation de découvert, des frais de dossier peuvent être facturés par les banques et malgré l’autorisation un taux d’intérêt débiteur sera appliqué, même si ce dernier sera moins élevé que dans une situation de dépassement non autorisé.
</p>
<h3>Les incidents liés aux moyens de paiement</h3>
<p>Ils peuvent prendre plusieurs formes notamment des frais de rejet lorsque le solde du compte n’est plus suffisant et qu’un chèque a été émis ou encore un prélèvement.
</p><p>
Ils peuvent également correspondre à des frais d’opposition en cas de perte ou de vol de chèque ou carte bancaire. Par ailleurs, la carte bancaire est soumise à d’autres incidents comme le blocage après plusieurs essais du code confidentiel et par conséquent des frais de réédition du code voire la fabrication d’une nouvelle carte.
</p>
<h3>Les formules ou package bancaires</h3>
<p>Les offres groupées de services sont exclusivement proposées par les banques traditionnels puisqu’ils incluent des services courants qui sont gratuits chez les banques en ligne. Ils comprennent généralement une carte bancaire, l’exonération des frais de tenue de compte, les services de banque à distance, l’assurance des moyens de paiement, un découvert autorisé, un chéquier et des chèques de banque et sont parfois personnalisables avec des options.
</p><p>
Étant donné leur coût élevé, ils peuvent être intéressants lorsque la consommation bancaire est effectivement importante et que les services incluent correspondent aux réels besoins du consommateur.
</p>
<h2>S’aider d’un comparateur de banque en ligne</h2>
<p>
Pour trouver l’établissement le moins cher selon les tarifs bancaires de base le consommateur peut avoir recours à un comparateur de banque en ligne pour faciliter sa recherche. S’il le souhaite, il pourra aller plus loin en consultant les grilles tarifaires des banques.
</p>
			</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
